import React from 'react'
import * as PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout'
import SEO from '../components/SEO/SEO'
import Content, { HTMLContent } from '../components/Content'
import { getCurrentLangKey } from 'ptz-i18n'
import { FormattedMessage } from 'react-intl'
import { Format } from 'react-intl-format'
import HireUs from '../components/HireUs'

function encode(data) {
    return Object.keys(data)
        .map(
            (key) =>
                encodeURIComponent(key) + '=' + encodeURIComponent(data[key])
        )
        .join('&')
}

function setActionPath(langKey) {
    let path
    if (langKey === 'en') {
        path = '/en/contact/thanks/'
    } else {
        path = '/ka/contact/thanks/'
    }
    return path
}

const ContactPageTemplate = ({ contentComponent }) => {
    const PageContent = contentComponent || Content
    return (
        <section className="section">
            <div className="container">
                <div className="columns">
                    <div className="column is-offset-3 is-6">
                        <h2 className="is-size-1 has-text-primary has-text-centered has-text-weight-normal">
                            <FormattedMessage id="contact.title" />
                        </h2>
                        <HireUs />
                    </div>
                </div>
            </div>
        </section>
    )
}

ContactPageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
    contentComponent: PropTypes.func,
}

class ContactPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = { isValidated: false }
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value })
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const form = e.target
        fetch('/?no-cache=1', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({
                'form-name': form.getAttribute('name'),
                ...this.state,
            }),
        })
            .then(() => navigate(form.getAttribute('action')))
            .catch((error) => alert(error))
    }
    render() {
        let dataMarkdown = []
        let data
        if (this.props.data !== null) {
            dataMarkdown = this.props.data.markdownRemark
            data = this.props.data
        }
        const location = this.props.location
        const url = location.pathname
        const { langs, defaultLangKey } = data.site.siteMetadata.languages
        this.langKey = getCurrentLangKey(langs, defaultLangKey, url)
        const action = setActionPath(this.langKey)
        const jsonData = data.allArticlesJson.edges[0].node.articles
        const { frontmatter } = dataMarkdown
        return (
            <Layout
                className="container"
                data={data}
                jsonData={jsonData}
                location={location}
            >
                <SEO frontmatter={frontmatter} />
                <Format>
                    {(intl) => (
                        <div className="container">
                            <ContactPageTemplate
                                contentComponent={HTMLContent}
                                title={dataMarkdown.frontmatter.title}
                                content={dataMarkdown.html}
                                onSubmit={this.handleSubmit}
                                action={action}
                            />
                        </div>
                    )}
                </Format>
            </Layout>
        )
    }
}

ContactPage.propTypes = {
    data: PropTypes.object.isRequired,
}

export default ContactPage

export const pageQuery = graphql`
    query ContactPageQuery($id: String!) {
        site {
            siteMetadata {
                languages {
                    defaultLangKey
                    langs
                }
            }
        }
        allArticlesJson(filter: { title: { eq: "home" } }) {
            edges {
                node {
                    articles {
                        en
                        ka
                    }
                }
            }
        }
        markdownRemark(id: { eq: $id }) {
            html
            frontmatter {
                id
                title
                description
                tags
                lang
            }
            fields {
                slug
            }
        }
    }
`
